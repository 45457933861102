import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default function useJustificationproposalsList() {
  // Use toast
  const toast = useToast()

  const refJustificationproposalsListTable = ref(null)

  const userData = getUserData()

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'periodo', sortable: true },
    { key: 'stato', sortable: true },
    { key: 'destinatario', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'id',
    periodo: 'start_day',
    tipo: 'status',
    destinatario: 'user',
  }

  const perPage = ref(10)
  const totalJustificationproposals = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const userFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refJustificationproposalsListTable.value
      ? refJustificationproposalsListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalJustificationproposals.value,
    }
  })

  const refetchData = () => {
    refJustificationproposalsListTable.value.refresh()
  }

  watch([currentPage, perPage, userFilter, statusFilter], () => {
    refetchData()
  })

  const fetchJustificationproposals = (ctx, callback) => {
    store
      .dispatch('app-justificationproposals/fetchJustificationproposals', {
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        show_user: 1,
        user_id: userFilter.value,
        status: statusFilter.value,
        for_approvation: 1,
      })
      .then(response => {
        const justificationproposals = response.data[0]
        const total = response.data[1]

        callback(justificationproposals)
        totalJustificationproposals.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  const mediaUrl = process.env.VUE_APP_MEDIA_URL

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => moment(date).format('DD/MM/YYYY')

  const resolveTypeName = type => {
    if (type === 'department') return 'Reparto'
    if (type === 'users') return 'Utenti'
    return ''
  }

  const resolveTypeIcon = type => {
    if (type === 'department') return 'UsersIcon'
    if (type === 'users') return 'UserIcon'
    return ''
  }

  const resolveTypeColor = type => {
    if (type === 'department') return 'warning'
    if (type === 'users') return 'info'
    return ''
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'danger'
  }

  const resolveStatusIcon = status => {
    if (status === 'approved') return 'CheckIcon'
    if (status === 'waiting') return 'PauseIcon'
    if (status === 'rejected') return 'XIcon'
    if (status === 'deleted') return 'TrashIcon'
    return 'PauseIcon'
  }

  const resolveStatusName = (status, max_days, selected_days) => {
    if (status === 'approved' && max_days == selected_days) return 'Approvata'
    if (status === 'approved' && max_days != selected_days) return 'Approvata (in parte)'
    if (status === 'waiting') return 'In Attesa'
    if (status === 'rejected') return 'Rifiutata'
    if (status === 'deleted') return 'Annullata'
    return 'Sconosciuto'
  }

  const resolveStatusColor = status => {
    if (status === 'approved') return 'text-success'
    if (status === 'waiting') return 'text-secondary'
    if (status === 'rejected') return 'text-warning'
    if (status === 'deleted') return 'text-danger'
    return 'text-warning'
  }

  return {
    fetchJustificationproposals,
    tableColumns,
    perPage,
    currentPage,
    totalJustificationproposals,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refJustificationproposalsListTable,

    refetchData,

    parseDate,
    resolveTypeName,
    resolveTypeIcon,
    resolveTypeColor,
    resolveUserRoleVariant,
    resolveStatusIcon,
    resolveStatusName,
    resolveStatusColor,

    mediaUrl,
    userFilter,
    statusFilter,
    userData,
  }
}
